import { Button, Icon, Text } from '@chakra-ui/react';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { PageRoutes } from 'constants/Routes';
import { useRouter } from 'next/router';
import { FC } from 'react';

interface IViewMenusButtonProps {
  pathSegments: string[];
  /** @deprecated FIXME: This prop should be deleted. Use the Chakra styling system instead. */
  size?: 'lg' | 'md';
  /** Whether to render as a button (primary action) or a link (secondary action)  */
  as: 'button' | 'link';
}

const VIEW_MENUS = 'View Menus';

const ViewMenusButton: FC<IViewMenusButtonProps> = ({
  pathSegments,
  size = 'lg',
  as: renderAs,
}) => {
  const router = useRouter();
  const handleOrderNowClick = () => {
    if (pathSegments.length === 1) {
      router.push(`/${pathSegments[0]}/order`);
    } else {
      router.push(PageRoutes.LOCATIONS);
    }
  };

  const showArrow = renderAs === 'button';

  const commonProps = {
    color: 'white',
    borderRadius: '11px',
    onClick: handleOrderNowClick,
  } as const;

  const linkProps = {
    variant: 'link',
  } as const;

  const buttonProps = {
    _hover: {
      bg: 'brand.400',
    },
    _active: {
      bg: 'brand.400',
    },
    background: 'primary',
  } as const;

  return size === 'lg' ? (
    <Button
      height="56px"
      fontSize="16px"
      lineHeight="16px"
      rightIcon={
        showArrow ? (
          <Icon fontSize="xl">
            <ArrowRightIcon />
          </Icon>
        ) : undefined
      }
      {...commonProps}
      {...(renderAs === 'button' ? buttonProps : linkProps)}>
      <Text paddingRight={{ base: 0, md: '15px' }}>{VIEW_MENUS}</Text>
    </Button>
  ) : (
    <Button
      boxShadow="2xl"
      padding="24px"
      justifyContent="space-between"
      width="100%"
      lineHeight="23px"
      letterSpacing="0.1em"
      fontWeight="400"
      fontSize="14px"
      textTransform="uppercase"
      position="relative"
      height="48px"
      mb="8px"
      rightIcon={<ArrowRightIcon width="16px" />}
      {...commonProps}
      {...(renderAs === 'button' ? buttonProps : linkProps)}>
      <Text>{VIEW_MENUS}</Text>
    </Button>
  );
};
export default ViewMenusButton;
