import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

const BANNER_KEY = 'banner';

export const useBannerText = (banner?: string | null) => {
  const [bannerText, setBannerText] = useState<string | null>();

  useEffect(() => {
    const now = DateTime.now();
    const bannerFromStorage = localStorage.getItem(BANNER_KEY);
    const parsedBannerObject = bannerFromStorage && JSON.parse(bannerFromStorage);
    if (parsedBannerObject?.text !== banner || parsedBannerObject.date < now) {
      localStorage.removeItem(BANNER_KEY);
      setBannerText(banner);
    }
  }, [bannerText]);

  const handleHideBanner = () => {
    const expireTime = DateTime.now().plus({ hours: 1 });
    localStorage.setItem(BANNER_KEY, JSON.stringify({ text: banner, date: expireTime }));
    setBannerText(null);
  };

  return { bannerText, handleHideBanner };
};
