import { Box, chakra, Flex, IconButton, Text, useMultiStyleConfig } from '@chakra-ui/react';
import { XIcon } from '@heroicons/react/solid';
import { FC } from 'react';

export interface ICottageBannerProps {
  bannerText?: string | null;
  handleHideBanner: () => void;
}

const ChakraXIcon = chakra(XIcon);

export const CottageBanner: FC<ICottageBannerProps> = ({ bannerText, handleHideBanner }) => {
  // Multipart component styling configured in the Chakra UI theme system
  const themeStyles = useMultiStyleConfig('CottageBanner');

  if (!bannerText) {
    return <></>;
  }

  return (
    <Box id="banner" as="aside" __css={themeStyles.root}>
      <Flex __css={themeStyles.flex}>
        <Box __css={themeStyles.textContainer}>
          <Text sx={themeStyles.bannerText}>{bannerText}</Text>
        </Box>
        <Box position="absolute" __css={themeStyles.closeContainer}>
          <IconButton
            icon={<ChakraXIcon __css={themeStyles.closeIcon} />}
            onClick={handleHideBanner}
            variant="tertiary"
            aria-label="Close banner"
            __css={themeStyles.closeButton}
          />
        </Box>
      </Flex>
    </Box>
  );
};
