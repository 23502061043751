import { Box, Text, VStack } from '@chakra-ui/react';
import ViewMenusButton from 'components/Common/ViewMenusButton';
import { FC } from 'react';

interface IOrderTypeLinksProps {
  pathSegments: string[];
  title: string;
}

const OrderTypeLinks: FC<IOrderTypeLinksProps> = ({ pathSegments, title }) => {
  return (
    <VStack
      bg="brand.100"
      p={{ base: '32px 24px', md: '64px 48px' }}
      w="100%"
      gap="4"
      borderRadius="11px">
      <Text fontSize="xl">{title}</Text>
      <Box>
        <ViewMenusButton pathSegments={pathSegments} as="button" />
      </Box>
    </VStack>
  );
};
export default OrderTypeLinks;
