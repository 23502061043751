import { Box, Flex, useBreakpoint } from '@chakra-ui/react';
import { useAppContext } from 'context/AppContext/context';
import dynamic from 'next/dynamic';
import { FC, ReactNode, useEffect, useState } from 'react';
import ImageHelpers from 'utils/helpers/ImageHelpers';

import CottageBanner, { useBannerText } from '../Common/CottageBanner';

const Footer = dynamic(() => import('components/Footer'));
const Navigation = dynamic(() => import('components/Navigation'));

interface IAppLayout {
  showChangeLocationOption: boolean;
  showNavigationLinks: boolean;
  showFooter: boolean;
  children: ReactNode;
  showBanner?: boolean;
}

// Higher Order Component (HOC) that wraps every page in the app. It owns all common components such as
// the head tag, navigation components, and footer. It also accepts a children component which represents each
// page's specific content.
const AppLayout: FC<IAppLayout> = ({
  showChangeLocationOption,
  showNavigationLinks,
  showFooter,
  children,
  showBanner = false,
}) => {
  const { businessMetadata: metadata } = useAppContext();
  const social = metadata.social;
  const supportPhoneNumber = metadata.supportPhoneNumber;
  const supportEmail = metadata.supportEmail;
  const pathSegments = metadata.pathSegments;

  const toolbarImageFullPath = ImageHelpers.resolveImage(
    'assets/shop/your_business_name.svg',
    metadata.toolbarImage
  );

  const layout = useBreakpoint();
  const { bannerText, handleHideBanner } = useBannerText(metadata.banner);
  const [bannerHeight, setBannerHeight] = useState(0);

  const marketing = metadata.marketing;

  useEffect(() => {
    setBannerHeight(document.getElementById('banner')?.offsetHeight ?? 0);
  }, [bannerText, layout]);

  return (
    <Flex
      width="100%"
      height={showFooter ? 'unset' : '100%'}
      minHeight="100vh"
      justifyContent="space-between"
      flexDirection="column">
      {showBanner && <CottageBanner bannerText={bannerText} handleHideBanner={handleHideBanner} />}
      <Navigation
        showChangeLocationOption={showChangeLocationOption}
        pathSegments={pathSegments}
        showNavigationLinks={showNavigationLinks}
        toolbarImageFullPath={toolbarImageFullPath}
        supportEmail={supportEmail}
        supportPhoneNumber={supportPhoneNumber}
        showGiftCard={metadata.showGiftCard}
        showPlans={metadata.showPromotePlans}
        top={bannerHeight}
      />
      <Box flexGrow={1} overflowX="auto" marginTop={`${bannerHeight / 16}rem`}>
        {children}
      </Box>
      {showFooter ? (
        <Footer
          social={social}
          supportPhoneNumber={supportPhoneNumber}
          supportEmail={supportEmail}
          toolbarImageFullPath={toolbarImageFullPath}
          showGiftCard={metadata.showGiftCard}
          showPlans={metadata.showPromotePlans}
          showSubscribe={marketing?.enabled ?? false}
          subscribeUrl={marketing?.hostedSignUpUrl ?? undefined}
        />
      ) : null}
    </Flex>
  );
};

export default AppLayout;
